import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import { Box, Grid } from "@mui/material";
import { BackButton } from "Components/Buttons";
import TextIcon from "Components/Fields/TextIcon";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import FilterDropdown from "Components/StyledComponents/FilterDropdown";
import { MyRequestItem } from "features/myRequests";
import {
  IAgentOfferorRequest,
  useGetAgentOfferorRequestsQuery,
} from "features/offerors/api";
import { IAgentOfferListing } from "features/offers/api";
import { useState } from "react";

const MyRequests: React.FC = (): JSX.Element => {
  const { data: pendingListings, isLoading } =
    useGetAgentOfferorRequestsQuery();
  const [filterValue, setFilterValue] = useState("All");

  const filterMatching = (request: IAgentOfferorRequest) => {
    if (filterValue === "All") return true;
    return request.status === filterValue;
  };

  return (
    <Box sx={backgroundStyle}>
      <ConditionalWrapper isLoading={isLoading}>
        <Grid
          container
          flexDirection={"column"}
          sx={pendingLisitingContainerStyle}
          maxWidth="xl"
        >
          <Grid item>
            <BackButton variant="transparent" />
          </Grid>
          <Grid item sx={{ maxWidth: "100%" }}>
            <Grid container alignItems="end" gap={3} mb={3}>
              <Grid item flexGrow={1}>
                <TextIcon
                  variant="h6Bold"
                  fontColor="primary.main"
                  icon={<QuizOutlinedIcon color="primary" />}
                  text="My Requests"
                />
              </Grid>
              <Grid item>
                <FilterDropdown
                  mb={0}
                  onChange={setFilterValue}
                  value={filterValue}
                  options={filterOptions}
                />
              </Grid>
            </Grid>
            {pendingListings?.map((listing: IAgentOfferListing) =>
              listing?.offeror_requests?.map((request: IAgentOfferorRequest) =>
                filterMatching(request) ? (
                  <MyRequestItem
                    key={request.id}
                    request={request}
                    listing={listing}
                  />
                ) : null
              )
            )}
          </Grid>
        </Grid>
      </ConditionalWrapper>
    </Box>
  );
};

export default MyRequests;

const pendingLisitingContainerStyle = {
  mx: "auto",
  width: "100%",
  my: 3,
  px: { xs: 1, md: 5 },
};

const backgroundStyle = {
  backgroundColor: "grey.50",
  height: "100%",
  width: "100%",
  position: "absolute",
};

const declineButtonStyle = {
  backgroundColor: "#FEF2F2",
  borderColor: "grey.600",
  color: "grey.900",
  "&:hover": {
    backgroundColor: "#FEF2F2",
  },
  "&:focus": {
    backgroundColor: "#FEF2F2",
  },
  "&:active": {
    backgroundColor: "#FEF2F2",
  },
};

const filterOptions = [
  { label: "All", value: "All" },
  { label: "Accepted", value: "approved" },
  { label: "Pending", value: "pending" },
  { label: "Denied", value: "denied" },
];
