import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { logout } from "store/features/authSlice";
import { TPopupType, openPopup } from "store/features/popupSlice";

const rtkQueryErrorMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const { popup } = api.getState();
    if (isRejectedWithValue(action) && popup.type === null) {
      const isUnverifiedError =
        action?.meta?.baseQueryMeta?.request?.url?.startsWith(
          "http://localhost:3000/api/private"
        ) &&
        action.payload.data?.message === "Unauthorized: email not verified";
      const popupType: TPopupType = isUnverifiedError ? "dialog" : "snackbar";

      api.dispatch(
        openPopup({
          isOpen: true,
          message: action.payload.data?.message,
          type: popupType,
        })
      );
    } else if (action?.payload?.status === 403) {
      api.dispatch(logout());
    }

    return next(action);
  };

export default rtkQueryErrorMiddleware;
