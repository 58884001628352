import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormContext } from "react-hook-form";
import TimerFieldIcon from "styles/icons/TimerFieldIcon";
import { ControlledTextField } from "../../FormComponents";
import { digitsRegex, textRegex } from "Components/Validation/regex";

const EditContractTab: React.FC = (): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12}>
        <Box>
          {/* <Grid
            container
            sx={{
              border: "solid #EEF2FF",
              borderWidth: "0 0 1px 0 ",
              pb: 2,
            }}
          >
            <Grid>
              <Grid item>
                <ControlledTextField
                  control={control}
                  labelColor="#4B5563"
                  type="number"
                  name="contract_due_date"
                  label="Signing Contract time limit:"
                  placeholder="Time Limit"
                  startAdornment={<TimerFieldIcon />}

                  // //disabled={!isDisabled}
                />
              </Grid>
            </Grid>
            <Grid item alignSelf={"center"} pl={1}>
              <Typography
                variant="extraSmallBoldText"
                color="primary"
                component={"span"}
              >
                Days
              </Typography>
            </Grid>
          </Grid> */}
          <Typography
            sx={{ pt: 2 }}
            variant="extraSmallBoldText"
            color="primary"
          >
            Seller's Attorney:
          </Typography>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              pr: { xs: 0, lg: 2 },
              maxWidth: { xs: "100%", lg: "70%" },
            }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              flexWrap={"nowrap"}
              gap={1}
            >
              <Grid item>
                <ControlledTextField
                  control={control}
                  labelColor="#4B5563"
                  name="attorney_first_name"
                  label="First Name"
                  placeholder="Attorney First Name"
                  regex={textRegex}
                  sx={{ minWidth: "60%" }}
                  //disabled={!isDisabled}
                />
              </Grid>
              <Grid item>
                <ControlledTextField
                  control={control}
                  labelColor="#4B5563"
                  name="attorney_last_name"
                  label="Last Name"
                  regex={textRegex}
                  placeholder="Attorney Last Name"
                  sx={{ minWidth: "60%" }}
                  //disabled={!isDisabled}
                />
              </Grid>
            </Grid>

            <ControlledTextField
              control={control}
              labelColor="#4B5563"
              name="attorney_email_address"
              label="Email"
              inputMode="email"
              placeholder="Attorney Email Adress"
              sx={{ minWidth: "100%" }}
              //disabled={!isDisabled}
            />
            <ControlledTextField
              control={control}
              labelColor="#4B5563"
              name="attorney_number"
              label="Phone Number"
              placeholder="Attorney Phone Number"
              inputMode="numeric"
              sx={{ minWidth: "100%" }}
              regex={digitsRegex}
              maxLength={10}
              //disabled={!isDisabled}
            />
            <ControlledTextField
              control={control}
              labelColor="#4B5563"
              name="security_hold"
              label="Security Hold:"
              placeholder="Security Hold Amount"
              infoText="Penalty if the winning offeror fails to sign contract"
              sx={{ minWidth: "100%" }}
              maxLength={4}
              regex={digitsRegex}
              //disabled={!isDisabled}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditContractTab;
