import {
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link } from "Components/StyledComponents";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useNavigate } from "react-router";
import { logout, selectAuth, setPortal } from "store/features/authSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { MoreVert } from "@mui/icons-material";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Portal, TPortal } from "features/users/types";
import {
  ComputerIcon,
  EditPencilIcon,
  PersonIcon,
  SettingsIcon,
} from "styles/icons";
import { useLogoutMutation } from "features/auth/api/authApi";

interface IProps {
  icon?: React.ReactNode;
  listing?: any;
  isDrawerOpen?: any;
  setIsDrawerOpen?: any;
  sx?: object;
  isPendingRequest?: boolean;
  isAgent?: boolean;
  isAdmin?: boolean;
}

const UserAvatarMenu: React.FC<IProps> = ({
  icon,
  sx,
  isPendingRequest,
  isAgent,
  isAdmin,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [logoutUser] = useLogoutMutation();
  const navigate = useNavigate();
  const { portal } = useAppSelector(selectAuth);
  const handleLogout = () => {
    dispatch(logout());
    logoutUser();
    navigate("/login");
  };
  const isUserPortal = portal === Portal.USER || portal === null;
  const isAgentPortal = portal === Portal.AGENT;
  const isAdminPortal = portal === Portal.ADMIN;

  const handlePortalChange = (newPortal: TPortal) => {
    dispatch(setPortal({ portal: newPortal }));
    navigate(newPortal === Portal.USER || null ? "/listings" : "/myListings");
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <IconButton sx={{ padding: 0, ...sx }} {...bindTrigger(popupState)}>
            {icon ? icon : <MoreVert />}
          </IconButton>
          <Menu
            MenuListProps={{ disablePadding: true }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            {...bindMenu(popupState)}
          >
            <MenuItem
              onClick={() => navigate("/my-account")}
              style={{ minWidth: "140px" }}
            >
              <ListItemIcon style={{ minWidth: "26px" }}>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant={"extraSmallBoldText"}>My Account</Typography>
            </MenuItem>
            {/*{!isPendingRequest && isUserPortal && !isAgent && !isAdmin && (
              <MenuItem style={{ minWidth: "140px" }}>
                <ListItemIcon style={{ minWidth: "26px" }}>
                  <EditPencilIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>
                  Sign Up as Agent
                </Typography>
              </MenuItem>
            )}*/}
            {isUserPortal && isAdmin && (
              <div>
                <MenuItem
                  style={{ minWidth: "140px" }}
                  onClick={() => handlePortalChange(Portal.AGENT)}
                >
                  <ListItemIcon style={{ minWidth: "26px" }}>
                    <ComputerIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant={"extraSmallBoldText"}>
                    Agent Portal
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{ minWidth: "140px" }}
                  onClick={() => handlePortalChange(Portal.ADMIN)}
                >
                  <ListItemIcon style={{ minWidth: "26px" }}>
                    <ComputerIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant={"extraSmallBoldText"}>
                    Admin Portal
                  </Typography>
                </MenuItem>
              </div>
            )}
            {isUserPortal && isAgent && (
              <MenuItem
                style={{ minWidth: "140px" }}
                onClick={() => handlePortalChange(Portal.AGENT)}
              >
                <ListItemIcon style={{ minWidth: "26px" }}>
                  <ComputerIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>
                  Agent Portal
                </Typography>
              </MenuItem>
            )}
            {isAgentPortal && (
              <div>
                <MenuItem
                  style={{ minWidth: "140px" }}
                  onClick={() => handlePortalChange(Portal.USER)}
                >
                  <ListItemIcon style={{ minWidth: "26px" }}>
                    <ComputerIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant={"extraSmallBoldText"}>
                    User Portal
                  </Typography>
                </MenuItem>
                {isAdmin && (
                  <MenuItem
                    style={{ minWidth: "140px" }}
                    onClick={() => handlePortalChange(Portal.ADMIN)}
                  >
                    <ListItemIcon style={{ minWidth: "26px" }}>
                      <ComputerIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant={"extraSmallBoldText"}>
                      Admin Portal
                    </Typography>
                  </MenuItem>
                )}
              </div>
            )}
            {(isAgentPortal ) && (
              <Link to="/settings">
                <MenuItem style={{ minWidth: "140px" }}>
                  <ListItemIcon style={{ minWidth: "26px" }}>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant={"extraSmallBoldText"}>
                    Settings
                  </Typography>
                </MenuItem>
              </Link>
            )}
            {isAdminPortal && (
              <div>
                <MenuItem
                  style={{ minWidth: "140px" }}
                  onClick={() => handlePortalChange(Portal.USER)}
                >
                  <ListItemIcon style={{ minWidth: "26px" }}>
                    <ComputerIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant={"extraSmallBoldText"}>
                    User Portal
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{ minWidth: "140px" }}
                  onClick={() => handlePortalChange(Portal.AGENT)}
                >
                  <ListItemIcon style={{ minWidth: "26px" }}>
                    <ComputerIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant={"extraSmallBoldText"}>
                    Agent Portal
                  </Typography>
                </MenuItem>
              </div>
            )}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon style={{ minWidth: "26px" }}>
                <ExitToAppOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant={"extraSmallBoldText"}>Logout</Typography>
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default UserAvatarMenu;
