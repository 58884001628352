import { Container, Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { useGetUserInfoQuery } from "features/users/api/userApi";
import { Portal } from "features/users/types";
import { ReactNode } from "react";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";

interface IProps {
  children: ReactNode;
}

const AccountSettingsContainer: React.FC<IProps> = ({
  children,
}): JSX.Element => {
  const { data: userData, isLoading: userLoading } = useGetUserInfoQuery();
  const { portal } = useAppSelector(selectAuth);
  console.log("111111111");
  const isAgent = portal === Portal.AGENT;
  const userName = userData?.user.first_name + " " + userData?.user.last_name;
  const userEmail = userData?.user.email;
  const userPhone = userData?.user.phone;
  return (
    <ConditionalWrapper isLoading={userLoading}>
      <Container>
        <Grid container flexDirection={"column"} my={5} maxWidth="xl">
          <Grid item mb={5}>
            <Typography variant="bigBoldText" color="grey.700" fontWeight={700}>
              Account Settings
            </Typography>
          </Grid>
          <Grid item mb={2}>
            <Typography mb={1} variant="smallBoldText" color="grey.700">
              {userName}
              {isAgent && (
                <Typography
                  component={"span"}
                  variant="extraSmallRegularText"
                  color="grey.500"
                >
                  {" "}
                  - RE Agent
                </Typography>
              )}
            </Typography>

            <Typography
              mb={0.5}
              variant="extraSmallRegularText"
              color="primary"
            >
              {userPhone}
            </Typography>

            <Typography variant="extraSmallRegularText" color="primary">
              {userEmail}
            </Typography>
          </Grid>
          {children}
        </Grid>
      </Container>
    </ConditionalWrapper>
  );
};

export default AccountSettingsContainer;
