import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { OfferorStatus } from "features/offerors/api";
import { IOfferData } from "features/offers/api";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { CheckRoundedIcon } from "styles/icons";

interface IProps {
  request: IOfferData;
  sx?: object;
}

const StatusOfferButton: React.FC<IProps> = ({ request, sx }): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const { openDialog } = useDialogContext();
  return (
    <Box sx={{ width: "100%", ...sx }}>
      {request.status === OfferorStatus.PENDING && (
        <Grid container gap={1}>
          <Grid item xs={12}>
            <LoadingButton
              variant="primary"
              fullWidth
              onClick={() =>
                openDialog("Accept", {
                  offerId: request.id,
                  listingId: listing.id,
                })
              }
            >
              Accept
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="tertiary"
              fullWidth
              onClick={() =>
                openDialog("Decline", {
                  request,
                  listingId: listing?.id,
                  address: listing?.full_address,
                })
              }
            >
              Decline
            </LoadingButton>
          </Grid>
        </Grid>
      )}
      <Grid item>
        {request?.status === OfferorStatus.APPROVED && (
          <LoadingButton
            sx={{ pointerEvents: "none" }}
            variant="success"
            size="medium"
            fullWidth
            startIcon={<CheckRoundedIcon />}
          >
            Accepted
          </LoadingButton>
        )}
        {request?.status === OfferorStatus.DENIED && (
          <LoadingButton
            sx={{ pointerEvents: "none" }}
            fullWidth
            variant="error"
            size="medium"
          >
            Declined
          </LoadingButton>
        )}
      </Grid>
    </Box>
  );
};

export default StatusOfferButton;
