import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { Link } from "Components/StyledComponents";
import ErrorText from "Components/StyledComponents/ErrorText";
import {
  useEmailWithTokenVerificationMutation,
  useUpdatedEmailWithTokenVerificationMutation,
} from "features/auth/api/authApi";
import { ErrorResponse } from "features/auth/types";
import { AccountServices } from "features/listing/types";
import { useGetUserInfoQuery } from "features/users/api/userApi";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { selectAuth, setUser } from "store/features/authSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ResetOrVerify } from "../login/forgotPassword";

const VerifyEmailWithToken: React.FC = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const location = useLocation();
  console.log("location", location);
  const isUpdate = location.pathname === "/update-email";
  const isVerify = location.pathname === "/verify-email";
  const { data: userData, isLoading: userLoading } = useGetUserInfoQuery();
  const userEmail = userData?.user?.email;
  const token = new URLSearchParams(location.search).get("token");
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const [
    updatedEmailWithTokenVerification,
    {
      isLoading: updatedEmailWithTokenLoading,
      error: updatedEmailWithTokenError,
      isSuccess: updatedEmailWithTokenSuccess,
    },
  ] = useUpdatedEmailWithTokenVerificationMutation();
  const updateErrorMessage =
    (updatedEmailWithTokenError as ErrorResponse)?.data?.message || "";
  const [
    emailWithTokenVerification,
    {
      isLoading: emailWithTokenLoading,
      error: emailWithTokenError,
      isSuccess: emailWithTokenSuccess,
    },
  ] = useEmailWithTokenVerificationMutation();
  const verifyErrorMessage =
    (emailWithTokenError as ErrorResponse)?.data?.message || "";

  useEffect(() => {
    if (isVerify) emailWithTokenVerification({ token });
    else if (isUpdate) updatedEmailWithTokenVerification({ token });
  }, []);

  useEffect(() => {
    if (emailWithTokenSuccess) {
      dispatch(
        setUser({
          ...authState,
          verified: true,
        })
      );
    }
  }, [emailWithTokenSuccess, dispatch]);
  return (
    <Grid container justifyContent={"center"}>
      {step === 1 ? (
        <Grid item xs={12} md={10} lg={8}>
          {emailWithTokenSuccess || updatedEmailWithTokenSuccess ? (
            <Grid
              container
              flexDirection={"column"}
              gap={5}
              alignContent={"center"}
            >
              <Typography
                variant="h5Bold"
                color={"primary"}
                textAlign={"center"}
              >
                Your email address has been
                <br />
                {isVerify ? "Verified" : "Updated"} successfully!
              </Typography>
              <Link
                to="/"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <LoadingButton
                  variant="primary"
                  loading={emailWithTokenLoading}
                >
                  Go to Home
                </LoadingButton>
              </Link>
            </Grid>
          ) : (
            <Grid
              container
              flexDirection={"column"}
              gap={5}
              alignContent={"center"}
            >
              <Typography
                variant="h5Bold"
                color={"primary"}
                textAlign={"center"}
              >
                There was an error {isVerify ? "verifying" : "updating"} your
                <br />
                email address:
                <Typography component={"span"}>
                  <ErrorText
                    sx={{ justifyContent: "center" }}
                    text={isUpdate ? updateErrorMessage : verifyErrorMessage}
                  />
                </Typography>
              </Typography>

              <LoadingButton
                onClick={() => setStep(2)}
                variant="primary"
                loading={emailWithTokenLoading}
              >
                Resend Verification Link
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      ) : (
        <ResetOrVerify
          dialogType={
            isVerify
              ? AccountServices.EMAIL_VERIFY
              : AccountServices.EMAIL_UPDATE
          }
          setStep={setStep}
          email={userEmail}
        />
      )}
    </Grid>
  );
};

export default VerifyEmailWithToken;
