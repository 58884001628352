import * as yup from "yup";

export const checkPasswordLengthMin = (
  password: string,
  options = {}
): boolean => {
  try {
    yup.string().min(8).validateSync(password);

    return true;
  } catch (error) {
    return false;
  }
};

export const checkPasswordLengthMax = (
  password: string,
  options = {}
): boolean => {
  try {
    yup.string().max(50).validateSync(password);

    return true;
  } catch (error) {
    return false;
  }
};

export const checkPasswordLowercase = (
  password: string,
  options = {}
): boolean => {
  try {
    yup
      .string()
      .matches(
        /^(?=.*[a-z])/,
        "Password must contain at least one lowercase letter."
      )
      .validateSync(password);

    return true;
  } catch (error) {
    return false;
  }
};

export const checkPasswordUppercase = (
  password: string,
  options = {}
): boolean => {
  try {
    yup
      .string()
      .matches(
        /^(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter."
      )
      .validateSync(password);

    return true;
  } catch (error) {
    return false;
  }
};

export const checkPasswordLowercaseUppercase = (
  password: string,
  options = {}
): boolean => {
  if (checkPasswordLowercase(password) && checkPasswordUppercase(password))
    return true;
  else {
    return false;
  }
};

export const checkPasswordNumeric = (
  password: string,
  options = {}
): boolean => {
  try {
    yup
      .string()
      .matches(
        /^(?=.*[0-9])/,
        "Password must contain at least one numeric digit."
      )
      .validateSync(password);
    return true;
  } catch (error) {
    return false;
  }
};

export const checkPasswordSpecialCharacters = (
  password: string,
  options = {}
): boolean => {
  try {
    yup
      .string()
      .matches(
        /^(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character."
      )
      .validateSync(password);
    return true;
  } catch (error) {
    return false;
  }
};

export const checkPasswordIncludeFirstName = (
  password: string,
  firstName: string
): boolean => {
  try {
    yup
      .string()
      .test(
        "not-include-first-name",
        "Password must not include your first name.",
        function (value) {
          if (
            value &&
            firstName &&
            value.toLowerCase().includes(firstName.toLowerCase())
          ) {
            return true;
          }
          return false;
        }
      )
      .validateSync(password);

    return true;
  } catch (error) {
    return false;
  }
};

export const checkPasswordIncludeLastName = (
  password: string,
  lastName: string
): boolean => {
  try {
    yup
      .string()
      .test(
        "not-include-last-name",
        "Password must not include your last name.",
        function (value) {
          if (
            value &&
            lastName &&
            value.toLowerCase().includes(lastName.toLowerCase())
          ) {
            return true;
          }
          return false;
        }
      )
      .validateSync(password);
    return true;
  } catch (error) {
    return false;
  }
};

export const checkPasswordNotIncludePersonalInfo = (
  password: string,
  options: any = {}
): boolean => {
  return (
    password?.length > 0 &&
    (options.firstName?.length > 0 || options.lastName?.length > 0) &&
    !checkPasswordIncludeLastName(password, options.lastName) &&
    !checkPasswordIncludeFirstName(password, options.firstName)
  );
};

export const passwordValidations = [
  checkPasswordLengthMin,
  checkPasswordLengthMax,
  checkPasswordLowercaseUppercase,
  checkPasswordNumeric,
  checkPasswordSpecialCharacters,
  checkPasswordNotIncludePersonalInfo,
];

export default passwordValidations;
