import { Box, Typography } from "@mui/material";
import { ProfileIcon } from "../icons";
import { formatPhone } from "features/listing/utils/listingsUtils";

interface IProps {
  name: string | null;
  phone?: string;
  email?: string;
  avatar?: React.ReactNode;
  sx?: object;
  fontColor?: string;
  iconStyle?: object;
  hideAvatar?: boolean;
}

const AttorneyCard: React.FC<IProps> = ({
  name,
  phone,
  email,
  avatar = null,
  hideAvatar = false,
  sx,
  fontColor = null,
}): JSX.Element => {
  return (
    <Box sx={{ ...containerStyle, ...sx }}>
      {!hideAvatar && (
        <Box sx={{ display: "flex" }}>
          {avatar ? avatar : <ProfileIcon sx={{ width: 42, height: 42 }} />}
        </Box>
      )}
      <Box sx={attorneyCardContainerStyle}>
        <Box>
          <Typography variant="smallBoldText" color={fontColor || "grey.700"}>
            {name}
          </Typography>
        </Box>
        {email && (
          <Box>
            <Typography
              variant="extraSmallRegularText"
              color={fontColor || "grey.700"}
            >
              {email}
            </Typography>
          </Box>
        )}
        {phone && (
          <Box>
            <Typography
              variant="extraSmallRegularText"
              color={fontColor || "grey.500"}
            >
              {formatPhone(phone)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AttorneyCard;

const containerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "grey.50",
  border: "1px solid",
  borderColor: "grey.600",
  borderRadius: 1,
  padding: "12px 14px",
  gap: 1.5,
};

const attorneyCardContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 0.5,
};
export const cardListingStyle = {
  width: "min-content",
  minHeight: 35,
  color: "primary.main",
  alignItems: "center",
  padding: "8px 12px",
  ml: 0,
  ".MuiSvgIcon-root": {
    width: 32,
    height: 32,
  },
};
export const attorneyCardStyle = {
  width: "100%",
  minHeight: 35,
  color: "grey.700",
  alignItems: "center",
};
