import { yupResolver } from "@hookform/resolvers/yup";
import { KeyboardBackspace } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { columnsDropzoneStyle } from "Components/FormComponents/ControlledDropzone";
import { Button } from "Components/StyledComponents";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import TabsPanel from "Components/TabsPanel";
import { addOrEditListing } from "Components/TabsPanel/tabsData";
import dayjs from "dayjs";
import {
  useAddContingenciesMutation,
  useAddNewListingMutation,
  useDeleteContingenciesMutation,
  useEditListingMutation,
  useGetAgentListingDetailsQuery,
  useSetWaiveRequiredMutation,
  useUploadPhotosMutation,
} from "features/listing/api/agentApi";
import { NewListingSchema } from "features/listing/schema/NewListingSchema";
import {
  IListingDetails,
  ListingStatus,
  SaleType,
} from "features/listing/types";
import {
  modifyToAdd,
  modifyToDelete,
  modifyToWaiveRequired,
} from "features/listing/utils/agentListing";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { listingState, setCurrentListing } from "store/features/listingSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
import { useDialogContext } from "store/hooks/DialogsContext";
import { fullWidthTabsStyle } from "styles/components/StyledTabs";

type FileUpload = {
  fileType: string;
  reportData: any;
  description?: string;
};

interface IProps {
  isDrawer: boolean;
}

const EditOrAddListing: React.FC<IProps> = ({ isDrawer }): JSX.Element => {
  const itemId = useParams().itemId!;
  const { openDialog } = useDialogContext();
  const { listing } = useAppSelector(listingState);
  const dispatch = useAppDispatch();
  const { images, setDrawerState, drawerState } = useCurrentListingContext();
  const isAddMode = drawerState.mode === "add";
  const {
    data: singleListingData,
    isLoading: listingLoading,
    isSuccess: isGetSingleSuccess,
  } = useGetAgentListingDetailsQuery(listing?.id || itemId, {
    skip: !listing,
  });
  const [editListing, { isLoading: editListingLoading }] =
    useEditListingMutation();
  const [addContingencies, { isLoading: addContingenciesLoading }] =
    useAddContingenciesMutation();
  const [deleteContingencies, { isLoading: deleteContingenciesLoading }] =
    useDeleteContingenciesMutation();
  const [setWaiveRequired, { isLoading: setWaiveRequiredLoading }] =
    useSetWaiveRequiredMutation();
  const [uploadPhotos, { isLoading: uploadPhotosLoading }] =
    useUploadPhotosMutation();
  const [addNewListing, { isLoading: addNewListingLoading }] =
    useAddNewListingMutation();
  const methods = useForm<IAddNewListing>({
    resolver: yupResolver(NewListingSchema),
  });
  const canOpenPublish =
    listing?.status === ListingStatus.DRAFT && listing?.archived === false;
  const handlePhotoUpload = (fileUploads: FileUpload[], listingID: any) => {
    fileUploads.forEach((fileUpload) => {
      const { fileType, reportData, description } = fileUpload;
      if (reportData) {
        const formData = new FormData();
        formData.append("file_name", reportData);
        if (description) formData.append("description", description);
        uploadPhotos({
          listing_id: Number(listingID),
          file_type: fileType,
          body: formData,
        });
      }
    });
  };
  const handleContingencies = async (cbList: any, listingID: string) => {
    const existList: any[] = singleListingData?.contingencies || [];
    const contingenciesToAdd = modifyToAdd(cbList, existList);
    const contingenciesToDelete = modifyToDelete(existList, cbList);
    const handleWaiveRequired = modifyToWaiveRequired(cbList);
    if (contingenciesToAdd?.contingencies?.length ?? 0 > 0) {
      await addContingencies({
        listingId: listingID,
        body: contingenciesToAdd,
      });
    }
    if (contingenciesToDelete?.contingency_ids?.length ?? 0 > 0) {
      await deleteContingencies({
        listingId: listingID,
        body: contingenciesToDelete,
      });
    }

    if (handleWaiveRequired?.contingencies?.length ?? 0 > 0) {
      await setWaiveRequired({
        listingId: listingID,
        body: handleWaiveRequired,
      });
    }
  };

  const handlenewListingForm: SubmitHandler<IAddNewListing> = async (
    data: IAddNewListing
  ) => {
    const { inspection_report, title_report, contingencies = [] } = data;

    const arrangedDataToSend = modifiedDataToSend(singleListingData, data);
    try {
      const [addNewListingResponse] = await Promise.allSettled([
        isAddMode
          ? addNewListing(arrangedDataToSend)
          : editListing({
              id: listing?.id || itemId,
              body: arrangedDataToSend,
            }),
      ]);
      if (addNewListingResponse.status === "fulfilled") {
        const tiTleReport =
          methods.getValues("title_report") !== undefined &&
          methods.getValues("title_report") !== null &&
          methods.getValues("title_report") !== "";

        const inspectionReport =
          methods.getValues("inspection_report") !== undefined &&
          methods.getValues("inspection_report") !== null &&
          methods.getValues("inspection_report") !== "";

        const fileUploads = [];
        if (!!tiTleReport) {
          fileUploads.push({
            fileType: "title-report",
            reportData: new File(
              [title_report],
              `${methods.getValues("title_report").name}`,
              {
                type: "application/pdf",
              }
            ),
          });
        }
        if (!!inspectionReport) {
          fileUploads.push({
            fileType: "inspection-report",
            reportData: new File(
              [inspection_report],
              `${methods.getValues("inspection_report").name}`,
              {
                type: "application/pdf",
              }
            ),
          });
        }

        const newImages = images.filter((img) => img.isNew);
        const isMainPhotoExist =
          listing?.main_photo !== "" && listing?.main_photo !== undefined;
        if (newImages && newImages.length > 0) {
          if ((!isMainPhotoExist || isAddMode) && newImages[0]) {
            fileUploads.push({
              fileType: "main-photo",
              reportData: new File([newImages[0].original], "main-photo", {
                type: "image/png",
              }),
            });

            fileUploads.push(
              ...newImages.slice(1).map((image, index) => ({
                fileType: "additional-photos",
                description: newImages[index + 1].description,
                reportData: new File(
                  [image.original],
                  `image${images.length + index}`,
                  {
                    type: "image/png",
                  }
                ),
              }))
            );
          } else if (isMainPhotoExist && !isAddMode) {
            fileUploads.push(
              ...newImages.slice(0).map((image, index) => ({
                fileType: "additional-photos",
                description: newImages[index].description,
                reportData: new File(
                  [image.original],
                  `image${images.length + index}`,
                  {
                    type: "image/png",
                  }
                ),
              }))
            );
          }
        }
        const data = (addNewListingResponse as { value: { data: any } }).value
          .data;
        data?.id
          ? handleContingencies(contingencies, data.id)
          : handleContingencies(contingencies, listing?.id || itemId);
        data?.id
          ? handlePhotoUpload(fileUploads, data.id)
          : handlePhotoUpload(fileUploads, listing?.id || itemId);
      }
    } catch (error) {
      console.error("error", error);
    }
    if (isDrawer) {
      setDrawerState((prev) => ({
        isOpen: !prev.isOpen,
        mode: prev.mode,
      }));
    }
  };
  useEffect(() => {
    if (isGetSingleSuccess && singleListingData) {
      const inputKeys = Object.keys(emptyInputs);
      Object.entries(singleListingData).forEach(([name, value]: any) => {
        if (inputKeys.includes(name)) {
          if (name === "auction_start_date" || name === "auction_end_date") {
            methods.setValue(name, dayjs.unix(value));
          } else {
            methods.setValue(name, value);
          }
        }
      });
      dispatch(setCurrentListing(singleListingData));
    }
  }, [singleListingData, isGetSingleSuccess]);
  const isLoading =
    listingLoading ||
    editListingLoading ||
    addContingenciesLoading ||
    deleteContingenciesLoading ||
    setWaiveRequiredLoading ||
    addNewListingLoading ||
    uploadPhotosLoading;
  return (
    <FormProvider {...methods}>
      <Box
        sx={isDrawer ? drawerContainerStyle : noDrawerContainerStyle}
        role="presentation"
        onSubmit={methods.handleSubmit(handlenewListingForm)}
        component="form"
      >
        <Grid container justifyContent={"space-between"}>
          {isDrawer && (
            <Grid
              item
              sx={{ cursor: "pointer" }}
              onClick={() => setDrawerState({ isOpen: false, mode: "" })}
            >
              <Typography variant="extraSmallRegularText" color="grey.500">
                <KeyboardBackspace
                  style={{ verticalAlign: "middle", marginRight: 5 }}
                />
                Back to My Listing
              </Typography>
            </Grid>
          )}

          <Grid item ml={"auto"}>
            <LoadingButton
              variant="secondary"
              loading={isLoading}
              sx={{ mr: 2 }}
              type="submit"
            >
              Save
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              variant="primary"
              onClick={() =>
                canOpenPublish &&
                openDialog("PublishStepper", { listing: listing })
              }
            >
              Publish
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid container flexDirection={"column"}>
          <Typography my={2} variant="bigBoldText" color="primary">
            {isAddMode ? "New Listing" : "Edit Listing"}
          </Typography>

          <Box sx={{ width: "100%", height: 40 }}>
            <TabsPanel
              sx={fullWidthTabsStyle}
              tabsList={addOrEditListing}
              fullWidth={true}
            />
          </Box>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default EditOrAddListing;

interface IAddNewListing {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zip?: string;
  description?: string;
  price?: string;
  sale_type?: string;
  offer_increment?: string;
  auction_start_date?: string;
  auction_end_date?: string;
  reserved?: boolean;
  reserved_amount?: string;
  reserved_disclosed?: boolean;
  automatic_winner?: boolean;
  property_type?: string;
  attorney_name?: string;
  attorney_first_name?: string;
  attorney_last_name?: string;
  attorney_email_address?: string;
  attorney_number?: string;
  security_hold?: string;
  inspection_report?: any;
  title_report?: any;
  contract?: any;
  other?: any;
  deed?: any;
  main_photo?: any;
  additional_photos?: any;
  files?: any;
  contingencies?: string[];
  contingency_disclaimer?: string;
}

const containerStyle = {
  flex: 1,
  py: 4,
  bgcolor: "grey.50",
  position: "relative",
};
const drawerContainerStyle = {
  ...containerStyle,
  px: { xs: 2, sm: 5, md: 10 },
  pt: "100px",
  width: { xs: "auto", md: 600 },
};

const noDrawerContainerStyle = {
  ...containerStyle,
  px: 0,
};

const emptyInputs = {
  address_line_1: undefined,
  address_line_2: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
  description: undefined,
  price: undefined,
  sale_type: undefined,
  offer_increment: undefined,
  auction_start_date: undefined,
  auction_end_date: undefined,
  reserved: undefined,
  automatic_winner: undefined,
  property_type: undefined,
  // contract_due_date: undefined,
  attorney_first_name: undefined,
  attorney_last_name: undefined,
  attorney_email_address: undefined,
  attorney_number: undefined,
  security_hold: undefined,
  inspection_report: undefined,
  title_report: undefined,
  main_photo: undefined,
  contingencies: undefined,
  additional_photos: undefined,
  contingency_disclaimer: undefined,
};

const modifiedDataToSend = (
  dataFromBackend: IListingDetails | undefined,
  data: IAddNewListing
) => {
  if (!dataFromBackend) {
    return data;
  }

  const arrangedData: IAddNewListing = {};

  for (const key in data) {
    const backendValue = dataFromBackend[key as keyof IListingDetails];
    let newValue = data[key as keyof IAddNewListing];
    if (backendValue === "" && newValue === null) continue;
    if (key === "auction_start_date" || key === "auction_end_date") {
      newValue = dayjs(data[key as keyof IAddNewListing]).unix();
    }
    if (String(backendValue) !== String(newValue)) {
      arrangedData[key as keyof IAddNewListing] = newValue;
    }
  }
  return arrangedData;
};
