import * as yup from "yup";
import { multiLineSchema } from "./reausableSchemas";

export const myRequestsDeclineSchema = yup.object().shape({
  denial_reason_name: yup.string().required("A deniel reason is required"),
  denial_notes: multiLineSchema,
  blocked: yup
  .bool()
  .oneOf([false])
});
