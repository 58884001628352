import * as yup from "yup";
import { InferType, object, string } from "yup";
import { validationSignupList } from "../components";

export const ChangePasswordDialogSchema = object({
	currentPassword: string().required("Password is required"),
	newPassword: yup
	.string()
	.required("New Password is required")
	.min(8, validationSignupList[0].text)
	.max(50, "password can't have more than 50 characters")
	.matches(/^(?=.*[a-z])(?=.*[A-Z])/, validationSignupList[1].text)
	.matches(/^(?=.*[0-9])/, validationSignupList[2].text)
	.matches(/^(?=.*[!@#$%^&*])/, validationSignupList[3].text)
	.test(
	  "not-include-first-name",
	  validationSignupList[4].text,
	  function (value) {
		const { first_name } = this.parent;
		if (
		  value &&
		  first_name &&
		  value.toLowerCase().includes(first_name.toLowerCase())
		) {
		  return false;
		}
		return true;
	  }
	)
	.test(
	  "not-include-last-name",
	  validationSignupList[4].text,
	  function (value) {
		const { last_name } = this.parent;
		if (
		  value &&
		  last_name &&
		  value.toLowerCase().includes(last_name.toLowerCase())
		) {
		  return false;
		}
		return true;
	  }
	)
	,
	confirmPassword: yup
	.string()
	.required("Confirm Password is required")
	.min(8, validationSignupList[0].text)
	.max(50, "password can't have more than 50 characters")
	.matches(/^(?=.*[a-z])(?=.*[A-Z])/, validationSignupList[1].text)
	.matches(/^(?=.*[0-9])/, validationSignupList[2].text)
	.matches(/^(?=.*[!@#$%^&*])/, validationSignupList[3].text)
	  .test('passwords-match', 'Passwords must match', function (value) {
		return value === this.parent.newPassword;
	  }),
  });
  
  export type ChangePasswordDialogType = InferType<typeof ChangePasswordDialogSchema>;
