import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { ControlledTextField } from "Components/FormComponents";
import { Link } from "Components/StyledComponents";
import { digitsRegex, textRegex } from "Components/Validation/regex";
import { useEmailVerificationMutation } from "features/auth/api/authApi";
import { AccountServices } from "features/listing/types";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { ErrorIcon } from "styles/icons";

const PurchaserInformationStep: React.FC = (): JSX.Element => {
  const { portal, verified } = useAppSelector(selectAuth);
  const { control } = useFormContext();
  const { openDialog } = useDialogContext();
  const [emailVerification] = useEmailVerificationMutation();
  const location = useLocation();
  const isGuest = portal === null;

  const handleTabKeyPress = (
    event: React.KeyboardEvent<HTMLElement>,
    nextField: string
  ) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const nextFieldElement = document.getElementsByName(
        nextField
      )[0] as HTMLElement;
      const nextBttnElement = document.getElementById(nextField);
      if (nextFieldElement) {
        nextFieldElement.focus();
      } else {
        nextBttnElement?.focus();
      }
    }
  };

  return (
    <Grid item>
      <Grid item textAlign={"center"} mb={4}>
        <Typography color="grey.800" variant="bigBoldText">
          Purchaser Information:
        </Typography>
        {verified || isGuest ? (
          <Typography color="grey.500" variant="extraSmallRegularText">
            Complete this process to get verified.
          </Typography>
        ) : (
          <Grid
            item
            justifyContent={"center"}
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            mr={0.5}
          >
            <ErrorIcon sx={{ fontSize: "1.25rem" }} />

            <Typography
              variant="extraSmallRegularText"
              component={"span"}
              color="grey.500"
            >
              Your Email address is not verified
            </Typography>
          </Grid>
        )}
      </Grid>
      {!isGuest && verified ? (
        <>
          <Grid container item className="attorneyInformationContainerStyle">
            <Grid item xs={12} sm={6} px={1}>
              <ControlledTextField
                fullWidth
                name={"purchaser_first_name"}
                control={control}
                label={"Purchaser First Name"}
                placeholder="Purchaser First Name"
                labelColor={"#111827"}
                regex={textRegex}
                maxLength={50}
                onKeyDown={(e) => handleTabKeyPress(e, "purchaser_last_name")}
              />
            </Grid>
            <Grid item xs={12} sm={6} px={1}>
              <ControlledTextField
                fullWidth
                name={"purchaser_address_line_1"}
                control={control}
                label={"Address (Line 1)"}
                maxLength={50}
                placeholder="Address (Line 1)"
                labelColor={"#111827"}
                onKeyDown={(e) =>
                  handleTabKeyPress(e, "purchaser_address_line_2")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} px={1}>
              <ControlledTextField
                name={"purchaser_last_name"}
                fullWidth
                control={control}
                label={"Purchaser Last Name"}
                placeholder="Purchaser Last Name"
                labelColor={"#111827"}
                regex={textRegex}
                maxLength={50}
                onKeyDown={(e) =>
                  handleTabKeyPress(e, "purchaser_address_line_1")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} px={1}>
              <ControlledTextField
                name={"purchaser_address_line_2"}
                fullWidth
                control={control}
                maxLength={50}
                label={"Address (Line 2)"}
                placeholder="Address (Line 2)"
                labelColor={"#111827"}
                onKeyDown={(e) => handleTabKeyPress(e, "purchaser_city")}
              />
            </Grid>
          </Grid>
          <Grid container item className="attorneyInformationContainerStyle">
            <Grid item xs={12} sm={6} px={1}>
              <ControlledTextField
                fullWidth
                multiline
                rows={4}
                name={"comments"}
                control={control}
                label={"Comments"}
                placeholder="type comments here..."
                labelColor={"#111827"}
                sx={{ minHeight: 116 }}
                onKeyDown={(e) => handleTabKeyPress(e, "next")}
              />
            </Grid>
            <Grid item container xs={12} sm={6} px={1}>
              <Grid item xs={10} md={12} lg={9}>
                <ControlledTextField
                  name={"purchaser_city"}
                  fullWidth
                  control={control}
                  label={"City"}
                  placeholder="City"
                  regex={textRegex}
                  labelColor={"#111827"}
                  onKeyDown={(e) => handleTabKeyPress(e, "purchaser_state")}
                />
              </Grid>
              <Grid item display={"flex"} gap={1} xs={10} md={12} lg={9}>
                <ControlledTextField
                  name={"purchaser_state"}
                  control={control}
                  label={"State"}
                  placeholder="State"
                  labelColor={"#111827"}
                  maxLength={2}
                  regex={textRegex}
                  fullWidth
                  onKeyDown={(e) => handleTabKeyPress(e, "purchaser_zip")}
                />
                <ControlledTextField
                  sx={{ minWidth: 100, whiteSpace: "none" }}
                  name={"purchaser_zip"}
                  control={control}
                  label={"Zip Code"}
                  placeholder="Zip Code"
                  labelColor={"#111827"}
                  regex={digitsRegex}
                  maxLength={5}
                  fullWidth
                  onKeyDown={(e) => handleTabKeyPress(e, "comments")}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : isGuest ? (
        <Link
          to={"/login"}
          state={{ redirectToPublished: location.pathname }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <LoadingButton variant="primary" sx={{ mb: 2, maxWidth: "100%" }}>
            Log In to submit Verification
          </LoadingButton>
        </Link>
      ) : (
        <Grid container justifyContent={"center"}>
          <LoadingButton
            variant="primary"
            onClick={() => {
              openDialog("EmailVerification", {
                dialogType: AccountServices.EMAIL_VERIFY,
              });
              emailVerification();
            }}
          >
            Verify Your Email
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};

export default PurchaserInformationStep;
