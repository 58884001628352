import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { ControlledTextField } from "Components/FormComponents";
import { Link } from "Components/StyledComponents";
import ErrorText from "Components/StyledComponents/ErrorText";
import { useResetPasswordMutation } from "features/auth/api/authApi";
import { ResetPasswordSchema } from "features/auth/schema/LoginSchema";
import type { ErrorResponse } from "features/auth/types";
import { SubmitHandler, useForm } from "react-hook-form";
import ResetOrVerify from "./ResetOrVerify";
import { useEffect } from "react";

interface IResetPasswordForm {
  email: string;
}

interface IProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ForgotPassword: React.FC<IProps> = ({ step, setStep }): JSX.Element => {
  const [resetPassword, { isLoading, error, isSuccess }] =
    useResetPasswordMutation();
  const { handleSubmit, control, watch } = useForm<IResetPasswordForm>({
    mode: "onChange",
    resolver: yupResolver(ResetPasswordSchema),
  });

  const errorMessage = (error as ErrorResponse)?.data?.message || "";
  const handleResetPasswordForm: SubmitHandler<IResetPasswordForm> = ({
    email,
  }: IResetPasswordForm) => {
    resetPassword({ email });
  };

  useEffect(() => {
    if (isSuccess) {
      setStep(3);
    }
  }, [isSuccess]);
  if (step === 2) {
    return (
      <Box
        onSubmit={handleSubmit(handleResetPasswordForm)}
        component="form"
        p={{ xs: 2, sm: 5 }}
        my={3}
        sx={{
          bgcolor: "secondary.main",
          color: "text.primary",
          borderRadius: 1.5,
        }}
      >
        <Typography textAlign={"center"} variant="h5Bold" color={"primary"}>
          Password Assistance
        </Typography>
        <Typography variant="smallRegularText">
          Enter the email address associated with your Ephrown account.{" "}
        </Typography>
        <Grid
          container
          justifyContent={"space-between"}
          mt={3}
          mb={2}
          sx={borderEmailFormStyle}
        >
          <ControlledTextField
            mb={0}
            fullWidth
            control={control}
            name="email"
            inputMode="email"
            label="Email Address"
            type="email"
          />

          <Grid item mt={0.5}>
            {errorMessage && (
              <ErrorText color="error.main" text={errorMessage} />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} gap={3}>
          <LoadingButton
            onClick={() => setStep(1)}
            variant="tertiary"
            sx={{ mb: 3 }}
          >
            Back to login
          </LoadingButton>

          <LoadingButton
            variant="primary"
            type="submit"
            loading={isLoading}
            sx={{ mb: 3 }}
          >
            Continue
          </LoadingButton>
        </Grid>
        <Typography sx={{ textAlign: "center", my: 1 }} color="text.secondary">
          No Account?{" "}
          <Link
            to="/signup"
            style={{ color: "#6366F1", textDecoration: "underline" }}
          >
            Sign Up
          </Link>
        </Typography>
      </Box>
    );
  } else return <ResetOrVerify setStep={setStep} email={watch("email")} />;
};

export default ForgotPassword;

export const borderEmailFormStyle = {
  border: "1px solid #E5E7EB",
  borderWidth: "1px 0 0 0",
  pt: 2,
  pb: 2,
};
