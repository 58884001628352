import { Box, Grid, Typography } from "@mui/material";
import { calcProgressPercentageLeft } from "Components/DatesAndTime/helpers";
import {
  IListing,
  IListingDetails,
  ListingStatus,
  SaleType,
} from "features/listing/types";
import { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { useDialogContext } from "store/hooks/DialogsContext";
import { BorderLinearProgress } from "styles/components";
import { getTimerString } from "./TimerField";

type TLinearColor = "success" | "primary" | "error";
interface ITimerField {
  expiryTimestamp: Date;
  sx?: object;
  withIcon?: boolean;
  units?: 2 | 3 | 4;
  auctionStartDate: number;
  actualEndTime: number;
  linearColor?: TLinearColor;
  listing?: IListing | IListingDetails;
  hideProgress?: boolean;
  isWithoutProgress?: boolean;
}

const TimerProgressLine: React.FC<ITimerField> = ({
  expiryTimestamp,
  sx,
  linearColor,
  auctionStartDate,
  actualEndTime,
  units = 4,
  listing = null,
  hideProgress = false,
  isWithoutProgress = false,
}): JSX.Element => {
  const { openDialog, closeDialog } = useDialogContext();
  const isExpired = expiryTimestamp < new Date();

  // Function to generate a new initial timestamp
  const getInitialTimestamp = () => {
    return expiryTimestamp > new Date() ? expiryTimestamp : new Date();
  };
  // Calculate total seconds remaining
  const { seconds, minutes, hours, days, restart } = useTimer({
    expiryTimestamp: getInitialTimestamp(),
    onExpire: () =>
      (listing?.status === ListingStatus.LIVE ||
        listing?.status === ListingStatus.PUBLISHED) &&
      openDialog("SaleEnded", {
        text:
          listing?.status === ListingStatus.LIVE
            ? listing?.sale_type === SaleType.OPEN
              ? "Please wait for the page to reload"
              : "This Sale has ended! Please reload this page"
            : listing?.status === ListingStatus.PUBLISHED
            ? "This Listing is now Live! Please reload to go to the Live page"
            : "",
        hideReloadButton: listing?.sale_type === SaleType.OPEN && true,
        status: listing?.status,
      }),
  });

  const totalSecondsRemaining =
    seconds + minutes * 60 + hours * 3600 + days * 86400;

  useEffect(() => {
    closeDialog();
  }, [listing?.status]);

  useEffect(() => {
    restart(getInitialTimestamp());
  }, [expiryTimestamp]);

  let timerString = "";
  let timerProgress = 0;
  const isRedColor = isWithoutProgress && totalSecondsRemaining < 31;
  if (!isExpired) {
    timerString = getTimerString(seconds, minutes, hours, days, units);
    timerProgress = calcProgressPercentageLeft(auctionStartDate, actualEndTime);
  }
  if (
    (totalSecondsRemaining > 30 || totalSecondsRemaining === 0) &&
    !isWithoutProgress
  ) {
    return (
      <Box sx={{ ...sx, display: hideProgress ? "none" : "block" }}>
        <BorderLinearProgress
          color={linearColor || "primary"}
          variant="determinate"
          value={!isExpired ? timerProgress : 100}
          data-content={!isExpired ? `${timerString.trim()}` : "Expired"}
        />
      </Box>
    );
  } else
    return (
      <Grid container justifyContent={"center"} alignItems={"center"} gap={2}>
        <Typography
          color={isRedColor ? "error" : "primary"}
          variant="bigBoldText"
        >
          {!isExpired ? "Time to Sale End:" : "Sale Ended"}
        </Typography>
        <Typography color={isRedColor ? "error" : "primary"} variant="h4Bold">
          {timerString}
        </Typography>
      </Grid>
    );
};

export default TimerProgressLine;
