import {
  emailSchema,
  passwordSchema,
} from "Components/Validation/reausableSchemas";
import * as yup from "yup";

export const LoginSchema = yup.object().shape({
  // email: emailSchema,
  // password: passwordSchema.required("password is required"),
});

export const ResetPasswordSchema = yup.object().shape({
  // email: emailSchema,
});
