import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { setUser } from "store/features/authSlice";
import { useAppDispatch } from "store/hooks";

import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  ControlledPassword,
  ControlledTextField,
} from "Components/FormComponents";
import { digitsRegex, textRegex } from "Components/Validation/regex";
import {
  SignupSchema,
  useAgentVerificationMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
} from "features/auth";
import { ISignupForm } from "features/auth/types";
import { AccountServices } from "features/listing/types";
import { useGetUserInfoQuery } from "features/users";
import { ValidationTextList, validationSignupList } from "..";
import { ResetOrVerify } from "../login/forgotPassword";
import SignupBottom from "./SignupBottom";

const Signup: React.FC = () => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<number>(1);
  const [expanded] = useState<boolean>(false);
  const [
    registerUser,
    { isLoading: isRegisterLoading, isSuccess: isRegisterSuccess },
  ] = useRegisterUserMutation();
  const [
    loginUser,
    { data: loginData, isLoading: isLoginLoading, isSuccess: isLoginSuccess },
  ] = useLoginUserMutation();
  const [agentVerification, { isLoading: agentVerificationLoading }] =
    useAgentVerificationMutation();
  const { formState, handleSubmit, control, watch, clearErrors } =
    useForm<ISignupForm>({
      mode: "onChange",
      resolver: yupResolver(SignupSchema(expanded)),
    });
  const { data: userInfo, isSuccess: isUserInfoSuccess } = useGetUserInfoQuery(
    undefined,
    {
      skip: !isLoginSuccess,
    }
  );
  const handleRegisterForm: SubmitHandler<ISignupForm> = async (
    data: ISignupForm
  ) => {
    const verification = {
      license_number: data?.license,
      full_name: data?.full_name,
      document: data?.reLicense,
    };

    try {
      const userData = {
        ...data,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        state: data.state,
        zip: data.zip,
      };
      const registerResponse = await registerUser(userData).unwrap();
      if (
        "status" in registerResponse &&
        registerResponse.status === "success" &&
        expanded
      ) {
        await agentVerification(verification);
      }

      await loginUser({
        email: data?.email,
        password: data?.password,
      });
      setStep(2);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const reLicense = watch("reLicense");
  useEffect(() => {
    clearErrors(["full_name", "license"]);
  }, [reLicense, handleSubmit]);
  useEffect(() => {
    if (isUserInfoSuccess) {
      dispatch(
        setUser({
          username: loginData?.user_name,
          email: userInfo?.user.email,
          userTypes: loginData?.user_types,
          userId: loginData?.user_id,
          agentRequestStatus: loginData?.agent_request_status,
          portal: null,
          verified: loginData?.verified,
        })
      );
    }
  }, [isLoginSuccess, isUserInfoSuccess]);

  const isLoading =
    isRegisterLoading || isLoginLoading || agentVerificationLoading;

  return (
    <Grid container>
      {step === 1 ? (
        <Box
          className="boxOnCollapse"
          onSubmit={handleSubmit(handleRegisterForm)}
          component="form"
          sx={{
            bgcolor: "secondary.main",
            borderRadius: 1.5,
            p: 3,
            px: { xs: 1, sm: 3 },
          }}
        >
          <Typography variant="h5Bold" color={"primary"}>
            Sign Up
          </Typography>
          <Typography color="grey.700" variant="smallRegularText">
            You can either sign up as a user or as a RE Agent.
          </Typography>
          <Grid
            container
            justifyContent={"space-between"}
            mt={2}
            sx={{
              borderTop: "solid #E5E7EB",
              borderWidth: "1px 0",
              pt: 2,
              pb: 0,
            }}
          >
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                pr: { xs: 0, lg: 2 },
                maxWidth: { xs: "100%", lg: "50%" },
              }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                flexWrap={"nowrap"}
                gap={1}
              >
                <ControlledTextField
                  fullWidth
                  control={control}
                  name="first_name"
                  label="First Name"
                />

                <ControlledTextField
                  fullWidth
                  control={control}
                  name="last_name"
                  label="Last Name"
                />
              </Grid>
              <Grid container justifyContent={"space-between"}>
                <ControlledTextField
                  fullWidth
                  control={control}
                  name="username"
                  label="Username"
                  placeholder="Create a username"
                />
                <ControlledTextField
                  fullWidth
                  control={control}
                  name="email"
                  label="Email Address"
                  type="email"
                  inputMode="email"
                />
                <ControlledTextField
                  fullWidth
                  control={control}
                  name="phone"
                  label="Phone Number"
                  inputMode="numeric"
                  regex={digitsRegex}
                  maxLength={10}
                />
                <ControlledTextField
                  fullWidth
                  control={control}
                  name="address_line_1"
                  label="Address (Line 1)"
                />
                <ControlledTextField
                  fullWidth
                  control={control}
                  name="address_line_2"
                  label="Address (Line 2)"
                />
              </Grid>
              <Grid container spacing={1.5}>
                <Grid item xs={10} sm={5}>
                  <ControlledTextField
                    fullWidth
                    control={control}
                    name="city"
                    label="City"
                    regex={textRegex}
                  />
                </Grid>

                <Grid item xs={3} sm={3}>
                  <ControlledTextField
                    fullWidth
                    control={control}
                    name="state"
                    label="State"
                    regex={textRegex}
                    maxLength={2}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <ControlledTextField
                    fullWidth
                    control={control}
                    name="zip"
                    label="Zip Code"
                    maxLength={5}
                    regex={digitsRegex}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{ pl: { xs: 0, lg: 2 } }}
              className="signupBorderFields"
            >
              <Grid container justifyContent={"space-between"}>
                <ControlledPassword
                  fullWidth
                  control={control}
                  name="password"
                  label="Password"
                  placeholder="Create Password"
                />
                <ControlledPassword
                  mb={1}
                  fullWidth
                  control={control}
                  name="confirmPassword"
                  label="Confirm Password"
                />
                <ValidationTextList
                  password={watch("password", "")}
                  firstName={watch("first_name", "")}
                  lastName={watch("last_name", "")}
                  validationList={validationSignupList}
                />
              </Grid>
              <Divider
                sx={{ borderColor: "grey.600", my: 2 }}
                orientation={"horizontal"}
              />

              {/* <SignupCollapse
            setExpanded={setExpanded}
            expanded={expanded}
            control={control}
          /> */}
              <SignupBottom control={control} isLoading={isLoading} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <ResetOrVerify
          email={watch("email")}
          dialogType={AccountServices.EMAIL_VERIFY}
        />
      )}
    </Grid>
  );
};

export default Signup;
