enum Portal {
  USER = "user",
  AGENT = "agent",
  ADMIN = "admin",
}

type TPortal = Portal | null;

interface IUserInfo {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  username: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip: string;
}

interface IAgentRequestOfUser {
  status: string;
  legal_name: string;
  company_name: string;
  email: string;
  phone: string;
  license_number: string;
  license_attachment_pdf: string;
  denial_reason_name: string;
  denial_notes: string;
  blocked: boolean;
}

type TUserTypes = string[] | undefined | null;

interface IAuthstate {
  username: string | undefined | null;
  email: string | undefined | null;
  userTypes: TUserTypes;
  userId: number | undefined | null;
  agentRequestStatus: string | undefined | null;
  portal: Portal | null;
  verified: boolean | undefined | null;
}

export {
  IAgentRequestOfUser,
  IAuthstate,
  IUserInfo,
  Portal,
  TPortal,
  TUserTypes,
};
