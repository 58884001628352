import * as yup from "yup";
import {
  emailSchema,
  phoneSchema,
  nameSchema,
  usernameSchema,
  zipCodeSchema,
  stateSchema,
  stringSchema,
} from "Components/Validation/reausableSchemas";
import { validationSignupList } from "../components";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const SignupSchema = (expanded: boolean) =>
  yup.object().shape({
    first_name: nameSchema,
    last_name: nameSchema,
    email: emailSchema,
    username: usernameSchema,
    phone: phoneSchema,
    address_line_1: yup
      .string()
      .required("Address  is required")
      .matches(/^[a-zA-Z0-9\- '.,]+$/, "Invalid characters in the address")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters"),
    address_line_2: yup
      .string()
      .matches(/^[a-zA-Z0-9\- '.,]*$/, "Invalid characters in the address")
      .max(50, "Maximum 50 characters"),
    city: yup
      .string()
      .required("City is required")
      .matches(/^[a-zA-Z0-9\- '.,]+$/, "Invalid characters in the string")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters"),
    state: stateSchema,
    zip: zipCodeSchema,
    password: yup
      .string()
      .min(8, validationSignupList[0].text)
      .max(50, "password can't have more than 50 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])/, validationSignupList[1].text)
      .matches(/^(?=.*[0-9])/, validationSignupList[2].text)
      .matches(/^(?=.*[!@#$%^&*])/, validationSignupList[3].text)
      .test(
        "not-include-first-name",
        validationSignupList[4].text,
        function (value) {
          const { first_name } = this.parent;
          if (
            value &&
            first_name &&
            value.toLowerCase().includes(first_name.toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
      .test(
        "not-include-last-name",
        validationSignupList[4].text,
        function (value) {
          const { last_name } = this.parent;
          if (
            value &&
            last_name &&
            value.toLowerCase().includes(last_name.toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
      .required(),
    confirmPassword: yup
      .string()
      .label("confirm password")
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Password confirmation is required"),
    agree: yup.bool().oneOf([true], "Accept the terms and conditions"),
    license: yup.string().when("reLicense", {
      is: (reLicense: string) => expanded && !reLicense, // Use the 'expanded' argument in the condition
      then: (schema) => schema.required("License is required when expanded"),
      otherwise: (schema) => schema.notRequired(),
    }),
    full_name: yup.string().when("reLicense", {
      is: (reLicense: string) => expanded && !reLicense, // Use the 'expanded' argument in the condition
      then: (schema) => schema.required("Full Name is required when expanded"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
