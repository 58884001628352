import { stateSchema, stringSchema, zipCodeSchema } from "Components/Validation/reausableSchemas";
import * as yup from "yup";

export const updateAddressSchema = yup.object().shape({
  address_line_1: yup
  .string()
  .required("Full address is required")
  .matches(/^[a-zA-Z0-9\- '.,]*$/, "Invalid characters in the string")
  .min(3, "Minimum 3 characters")
  .max(50, "Maximum 50 characters"),
  address_line_2: yup
    .string()
    .matches(/^[a-zA-Z0-9\- '.,]*$/, "Invalid characters in the address")
    .max(50, "Maximum 50 characters"),
  city: yup
    .string()
    .required("City is required")
    .matches(/^[a-zA-Z0-9\- '.,]+$/, "Invalid characters in the string")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters"),
  state: stateSchema,
  zip: zipCodeSchema,
});
