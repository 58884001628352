import * as yup from "yup";

const emailSchema = yup
  .string()
  .required("email is required")
  .email("email must be vaild")
  .max(50, "Email cannot exceed 50 characters");

export { emailSchema };

