import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Typography } from "@mui/material";
import { Control, useController } from "react-hook-form";
import ErrorText from "../StyledComponents/ErrorText";

type TDropdown = {
  items: any;
  name: string;
  control: Control<any>;
};

const ControlledDeclineDropdown: React.FC<TDropdown> = ({
  items,
  name,
  control,
}): JSX.Element => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: "",
  });
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };
  return (
    <FormControl size="small" sx={dropdownContainerStyle} fullWidth>
      <Typography
        fontWeight={600}
        variant="extraSmallBoldText"
        sx={{ mb: 1, color: "grey.800" }}
      >
        Select reason Reasons For declining
      </Typography>
      <Select name={name} displayEmpty value={value} onChange={handleChange}>
        <MenuItem value="" disabled sx={{ display: "none" }}>
          Select a reason:
        </MenuItem>
        {items?.map((item: string) => (
          <MenuItem key={item} value={item}>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
      {error && <ErrorText text={error.message || ""} />}
    </FormControl>
  );
};

export default ControlledDeclineDropdown;

const dropdownContainerStyle = {
  my: 1,
  width: "100%",
  "& fieldset": {
    borderColor: "grey.600",
  },
};
