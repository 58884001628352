enum OfferorStatus {
  APPROVED = "approved",
  PENDING = "pending",
  PENDING_PAYMENT = "pending_payment",
  DENIED = "denied",
  NOT_VERIFIED = "not_verified",
  PAYMENT_FAILED= "payment_failed",
}

interface IUserOfferorRequest {
  status: OfferorStatus;
  attorney_email: string;
  attorney_name?: string;
  attorney_first_name: string;
  attorney_last_name: string;
  attorney_number: string;
  purchaser_address_line_1: string;
  purchaser_address_line_2: string;
  purchaser_city: string;
  purchaser_first_name: string;
  purchaser_last_name: string;
  purchaser_state: string;
  purchaser_zip: string;
  date_created: string;
  blocked: boolean;
  comments: string;
  proof_of_funds: string;
  denial_reason_name?: string;
  denial_notes: string;
  payment_method_last_four_digits: string;
  file_type: string;
  payment_method_id: string;
}

interface IAgentOfferorRequest {
  id: number;
  attorney_first_name: string;
  attorney_last_name: string;
  attorney_number: string;
  attorney_email: string;
  date_created: string;
  proof_of_funds: string;
  status: OfferorStatus;
  user_first_name: string;
  user_last_name: string;
  user_phone_number: string;
  user_email: string;
  denial_reason_name: string;
  denial_notes: string;
  full_address: string;
}

interface IListingOfferorRequests {
  id: number;
  status: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_phone_number: string;
  attorney_first_name: string;
  attorney_last_name: string;
  attorney_number: string;
  attorney_email: string;
  proof_of_funds: string;
  date_created: string;
  denial_reason_name: string;
  denial_notes: string;
  full_address: string;
}

export {
  OfferorStatus,
  IUserOfferorRequest,
  IAgentOfferorRequest,
  IListingOfferorRequests,
};
