import { Box, Grid, Typography } from "@mui/material";
import { digitsRegex } from "Components/Validation/regex";
import { useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Controller, useFormContext } from "react-hook-form";
import { EditPencilIcon, ExitSquareIcon } from "styles/icons";
import ControlledTextField from "./ControlledTextField/ControlledTextField";

interface IProps {
  isEditableOption?: boolean;
  isEditable?: boolean;
  setIsEditable?: ((value: boolean) => void) | undefined;
}

const ControlledFullAddress: React.FC<IProps> = ({
  isEditableOption = false,
  isEditable = false,
  setIsEditable,
}) => {
  const { control, setValue, reset, watch } = useFormContext();
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const handleOnChange = async (place: any) => {
    try {
      const results = await geocodeByPlaceId(place.value.place_id);
      const addressComponents = results[0].address_components;

      const postalCode = addressComponents
        .filter((component) => component.types.includes("postal_code"))
        .map((component) => component.long_name);

      setPostalCodeValue(postalCode[0] || "");

      const locality = addressComponents
        .filter((component) => component.types.includes("locality"))
        .map((component) => component.long_name);

      const sublocality = addressComponents
        .filter((component) => component.types.includes("sublocality"))
        .map((component) => component.long_name);
      
      setCityValue(locality[0] || sublocality[0] || "");

      const state = addressComponents
        .filter((component) =>
          component.types.includes("administrative_area_level_1")
        )
        .map((component) => component.short_name);

      setStateValue(state[0] || "");

      const streetNumber = addressComponents
        .filter((component) => component.types.includes("street_number"))
        .map((component) => component.long_name)
        .join(" ");
      const street = addressComponents
        .filter((component) => component.types.includes("route"))
        .map((component) => component.long_name)
        .join(" ");

      const updatedPlace = {
        label: `${streetNumber} ${street}` || "",
        value: place.value,
      };
      setValue("address_line_1", updatedPlace.label);
    } catch (error) {
      console.error("Error retrieving address details", error);
    }
  };

  const handleInputChange = (inputValue: any, actionMeta: any) => {
    if (actionMeta.action === "input-change") {
      setValue("address_line_1", inputValue);
    }
  };
  const isDisabled = true;
  return (
    <>
      <Box width={"100%"} mb={2}>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Typography
            fontWeight={600}
            variant="extraSmallBoldText"
            sx={labelGoogleStyle(isEditableOption)}
          >
            Address Line 1:
          </Typography>
          {isEditableOption && (
            <Box>
              {isEditable ? (
                <ExitSquareIcon
                  onClick={() => {
                    setIsEditable && setIsEditable(false);
                    reset();
                  }}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <EditPencilIcon
                  onClick={() => setIsEditable && setIsEditable(true)}
                  sx={{ cursor: "pointer" }}
                />
              )}
            </Box>
          )}
        </Grid>
        <Controller
          name="address_line_1"
          control={control}
          render={({ field }) => (
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
              apiOptions={{ language: "en" }}
              selectProps={{
                onInputChange: handleInputChange,
                value: {
                  label: field.value ?? "Search Address...",
                  value: field.value,
                },
                onChange: (value) => {
                  handleOnChange(value);
                },
                styles: getAutocompleteStyle(isEditable, field.value),
              }}
              autocompletionRequest={{
                types: ["address"],
                componentRestrictions: {
                  country: ["us"],
                },
              }}
            />
          )}
        />
      </Box>
      <ControlledTextField
        fullWidth
        sx={fieldsEditStyle(isEditable)}
        labelColor={isEditableOption ? "primary.main" : "#4B5563"}
        control={control}
        name="address_line_2"
        label="Address Line 2"
        placeholder="Search Address..."
        disabled={!isDisabled}
      />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <ControlledTextField
            fullWidth
            sx={fieldsEditStyle(isEditable)}
            labelColor={isEditableOption ? "primary.main" : "#4B5563"}
            control={control}
            name="city"
            label="City"
            autoCompleteValue={cityValue}
            placeholder="Enter City"
            disabled={!isDisabled}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTextField
            fullWidth
            sx={fieldsEditStyle(isEditable)}
            labelColor={isEditableOption ? "primary.main" : "#4B5563"}
            control={control}
            name="state"
            label="State"
            maxLength={2}
            autoCompleteValue={stateValue}
            placeholder="Enter State"
            disabled={!isDisabled}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTextField
            fullWidth
            sx={fieldsEditStyle(isEditable)}
            labelColor={isEditableOption ? "primary.main" : "#4B5563"}
            control={control}
            name="zip"
            label="Zip Code"
            regex={digitsRegex}
            maxLength={5}
            autoCompleteValue={postalCodeValue}
            placeholder="Enter Zip"
            disabled={!isDisabled}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ControlledFullAddress;

const labelGoogleStyle = (isEditableOption: boolean | undefined) => ({
  mb: 0.5,
  color: isEditableOption ? "primary.main" : "grey.800",
});

const fieldsEditStyle = (isEditable: boolean) => ({
  pointerEvents: isEditable ? "auto" : "none",
});

const getAutocompleteStyle = (isEditable: boolean, value: any) => {
  return {
    textInputContainer: {
      backgroundColor: "#fff0",
      borderWidth: 18,

      borderRadius: 3,
      padding: 0,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "100%",
    },
    control: (provided: any) => ({
      ...provided,
      borderRadius: 8,
      padding: "0 8px",
      fontSize: "0.8rem",
      fontFamily: "poppins",
      border: "1.5px solid #E5E7EB",
      cursor: "text",

      pointerEvents: isEditable ? "auto" : "none",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        background: "#F9FAFB",
      },
      ".css-1dimb5e-singleValue": {
        color: value ? "#4B5563" : "#bdc0c5",
      },

      ".css-1hb7zxy-IndicatorsContainer": {
        display: "none",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 999,
      fontSize: "1rem",
      color: "#4B5563",
    }),
  };
};
