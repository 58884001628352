import { ephrownApi } from "store/services/ephrownApi";
import { ILoginResponse } from "./responseTypes";

type LoginRequest = {
  email: string;
  password: string;
};

type EmailTokenVerification = {
  token: string | null;
};

type PasswordReset = {
  token: string | null;
  new_password: string;
};

type AgentVerificationRequest = {
  license_number: string;
  full_name: string;
  document: any;
};

export const authApi = ephrownApi.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<ILoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: "/api/pub/login",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    registerUser: builder.mutation<any, LoginRequest>({
      query: (body) => ({
        url: "/api/pub/signup",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    resetPassword: builder.mutation<any, { email: string }>({
      query: (body) => ({
        url: "/api/pub/request-reset-password",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    resetPasswordToken: builder.mutation<any, PasswordReset>({
      query: (body) => ({
        url: "/api/pub/reset-password-with-token",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    agentVerification: builder.mutation<
      ILoginResponse,
      AgentVerificationRequest
    >({
      query: (body) => ({
        url: "/api/private/agent-verification/signup",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    emailVerification: builder.mutation<any, void>({
      query: () => ({
        url: "/api/unverified/send-email-verification",
        method: "POST",
      }),
    }),
    emailWithTokenVerification: builder.mutation<any, EmailTokenVerification>({
      query: (token) => ({
        url: `/api/unverified/verify-email-with-otp`,
        method: "POST",
        body: token,
      }),
    }),
    updatedEmailWithTokenVerification: builder.mutation<any, EmailTokenVerification>({
      query: (token) => ({
        url: `/api/pub/verify-email-update-with-token`,
        method: "POST",
        body: token,
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: "/api/unverified/logout",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useResetPasswordMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
  useAgentVerificationMutation,
  useResetPasswordTokenMutation,
  useEmailWithTokenVerificationMutation,
  useEmailVerificationMutation,
  useLogoutMutation,
  useUpdatedEmailWithTokenVerificationMutation,
} = authApi;
